<template>
  <div class="subscribe">
    <div class="flex-column align-items-center">
      <h2 class="text-primary">{{msg}}</h2>
      <form id="payment-form" class="flex-column align-items-center" >
        <div>
          <div class="row">
            <div class="col-6">
              <div class="col-12 m-0 p-0">
                <label for="free" class="subscription-label">
                  <span class="fw-bold">Free</span> advertise <br/> for <span class="fw-bold">7 days</span> <br/><br/>
                  <div class="subscription-tickbox-container">
                    {{ subFreePrice }} <br/>
                    <input v-model="subscriptionPlan" type="radio" id="free" name="free" :value="subFreeValue">
                  </div>
                </label>
              </div>
            </div>
            <div class="col-6">
              <div class="col-12 m-0 p-0">
                <label for="user" class="subscription-label">
                  <span class="fw-bold">Paid</span> advertise <br/> for <span class="fw-bold">3 months</span> <br/><br/>
                  <div class="subscription-tickbox-container">
                    {{ subUserPrice }} <br/>
                    <input v-model="subscriptionPlan" type="radio" id="user" name="user" :value="subPaidValue">
                  </div>
                </label>
              </div>
            </div>
<!--            <div class="col-3">-->
<!--              <div class="col-12 m-0 p-0 min-height-100">-->
<!--                <p>10 advertises each for 6 months</p>-->
<!--              </div>-->
<!--              <div class="col-12 m-0 p-0">-->
<!--                {{ subAgentPrice }} <br/>-->
<!--                <input v-model="subscriptionPlan" type="radio" id="agent" name="agent" value="price_1JwT2VEQCztPLEB5HC7BEMY4">-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-3">-->
<!--              <div class="col-12 m-0 p-0 min-height-100">-->
<!--                <p>Unlimited advertises for unlimited time</p>-->
<!--              </div>-->
<!--              <div class="col-12 m-0 p-0">-->
<!--                {{ subAgencyPrice }} <br/>-->
<!--                <input v-model="subscriptionPlan" type="radio" id="agency" name="agency" value="price_1JwT2VEQCztPLEB5ToA3GGvT">-->
<!--              </div>-->
<!--            </div>-->
          </div>

          <div v-show="subscriptionPlan === subPaidValue" class="row m-0 p-0 plan-box">
            <div class="col-12">

              <div v-show="loadingPaymentMethods">
                <!-- Loading -->
                <Loader class="mt-2" :loading="loadingPaymentMethods" :small="false"></Loader>
              </div>

              <div v-if="this.$store.state.auth.authenticated === true && this.$store.state.auth.user && this.$store.state.auth.user.role && (this.$store.state.auth.user.role.name == 'Super Admin' || this.$store.state.auth.user.role.name == 'Premium')" class="form-group col-12 text-center">
                <!-- Start Horizontal Line with Icon -->
                <div class="horizontal-separator-box">
                  <span class="hr-line-short"></span>
                  <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                  <span class="hr-line-short"></span>
                </div>
                <!-- End Horizontal Line with Icon -->

                <!-- Information -->
                <small class="p-3 fw-bold green-text">Your account is PREMIUM, no need for card payment details</small>

                <!-- Start Horizontal Line with Icon -->
                <div class="horizontal-separator-box">
                  <span class="hr-line-short"></span>
                  <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                  <span class="hr-line-short"></span>
                </div>
                <!-- End Horizontal Line with Icon -->
              </div>

              <div v-show="this.$store.state.auth.authenticated === true && this.$store.state.auth.user && this.$store.state.auth.user.role && (this.$store.state.auth.user.role.name != 'Super Admin' && this.$store.state.auth.user.role.name != 'Premium')">
                <p class="text-start ps-2 font-13" v-show="!loadingPaymentMethods">Payment Methods</p>

                <div v-show="!loadingPaymentMethods && paymentMethodsLoadStatus === 2 && paymentMethods.length === 0"
                     class="payment-method border rounded row p-0 pt-2 pb-2 m-0 mt-2 mb-2 cursor-pointer">
                  No payment methods added, payment methods are added automatically when you do your first payment.
                </div>

                <div v-show="!loadingPaymentMethods && paymentMethodsLoadStatus === 2 && paymentMethods.length > 0">
                  <div v-for="(method, key) in paymentMethods"
                       v-bind:key="'method-'+key"
                       v-on:click="toggleSelectedMethod(method)"
                       class="payment-method border rounded row p-0 pt-2 pb-2 m-0 mt-2 mb-2 cursor-pointer"
                       v-bind:class="{ 'bg-selected-payment-method text-light': paymentMethodSelected && paymentMethodSelected.id === method.id }"
                  >
                    <div class="col-2 text-start">
                      <font-awesome-icon icon="credit-card" class="icon-fa" />
                      {{ method.brand.charAt(0).toUpperCase() }}{{ method.brand.slice(1) }}
                    </div>
                    <div class="col-4 text-start">
                      Card: {{ method.last_four }}
                    </div>
                    <div class="col-4 text-start">
                      Exp: {{ method.exp_month }}/{{ method.exp_year }}
                    </div>
                    <div class="col-2 text-center">
                    <span data-tooltip="tooltip">
                      <div class="no-text-wrap overflow-hidden text-truncate">
                        <button type="button"  :disabled="processing" @click.stop="removePaymentMethod(method.id)" class="delete-payment-method-btn d-inline">
                          <font-awesome-icon icon="times-circle" class="icon-fa required delete-icon" />
                        </button>
                        <span class="tooltiptext">Delete payment method</span>
                      </div>
                    </span>
                    </div>
                  </div>
                </div>

                <br/>
                <p class="text-start ps-2 font-13" v-show="!loadingPaymentMethods">Card Payment</p>

                <input v-show="!loadingPaymentMethods" id="card-holder-name" placeholder="Card holder name..." type="text" v-model="cardHolderName" class="form-control mb-2 font-13">

                <div v-show="!loadingPaymentMethods" id="payment-card" class="form-control">
                  <!-- A Stripe Element will be inserted here. -->
                </div>
              </div>
              <div id="error-message">
                <!-- Display error message to your customers here -->
                <div v-if="validationErrors" class="global-error mt-2">
                  <ValidationErrors :errors="validationErrors"/>
                </div>
              </div>
            </div>
          </div>
        </div>

<!--        <button :disabled="!subscriptionPlan && !paymentMethodSelected" class="btn btn-primary mt-3" id="add-card-button" v-on:click.prevent="updateSubscription(paymentMethodSelected)">-->
<!--          Subscribe-->
<!--        </button>-->
<!--        <br/><br/>-->

<!--        <input :disabled="processing" class="btn btn-primary shadow-sm" type="submit" value="Purchase" v-on:click.prevent="purchase" />-->
<!--        <br/><br/>-->
<!--        <div class="mt-3 mb-3">-->
<!--          OR-->
<!--        </div>-->
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ValidationErrors from './ValidationErrors';
import Loader from './Loader';

export default {
  name: 'Subscribe',
  components: {
    'ValidationErrors': ValidationErrors,
    'Loader': Loader
  },
  data () {
    return {
      stripeApiToken: '',
      stripe: '',
      elements: '',
      card: '',
      intentToken: '',
      cardHolderName: '',
      paymentStatus: 0,
      paymentError: '',
      paymentMethods: [],
      paymentMethodsLoadStatus: 0,
      paymentMethodSelected: {},
      loadingPaymentMethods: true,
      msg: 'Choose your plan',
      subFreePrice:"£0.00",
      subUserPrice:"£2.00",
      subAgentPrice:"£20.00",
      subAgencyPrice: "£100.00",
      subFreeValue: process.env.VUE_APP_STRIPE_SUB_FREE,
      subPaidValue: process.env.VUE_APP_STRIPE_SUB_PAID,
      subscriptionPlan: '',
      processingIntent: false,
      processing: false,
      paymentId: null,
      paymentAmount: null,
      validationErrors: null
    }
  },
  mounted: async function () {

    // Get stripe API key
    await this.getStripeApiKey();
    // Load User Intent for Stripe Payment System
    await this.loadUserIntentSubscribe();
    // Load User Payment Methods from Stripe (if exists)
    this.loadPaymentMethods();

    this.stripe = Stripe( this.stripeApiToken );

    this.elements = this.stripe.elements();
    this.card = this.elements.create('card', {
      hidePostalCode: true
    });

    this.card.mount('#payment-card');
  },
  methods: {
    async loadUserIntentSubscribe() {
      await this.loadIntent();
    },
    setAdPlanOnEdit(plan) {
      if (plan === true) {
        this.subscriptionPlan = this.subPaidValue;
      } else if (plan === false) {
        this.subscriptionPlan = this.subFreeValue;
      } else {
        this.subscriptionPlan = '';
      }
    },
    async getStripeApiKey() {
      this.processing = true;
      await axios.get('api/stripe/get-api-key').then(({data})=>{
        this.stripeApiToken = data;
        this.processing = false
      }).catch(({response})=>{
        alert(response.data.message)
        this.processing = false
      }).finally(()=> {
        this.processing = false
      })
    },
    async loadIntent() {
      this.processingIntent = true;
      await axios.get('api/user/setup-intent').then(({data})=>{
        this.intentToken = data;
        this.processingIntent = false
      }).catch(({response})=>{
        alert(response.data.message)
        this.processingIntent = false
      }).finally(()=> {
        this.processingIntent = false
      })
    },
    async loadPaymentMethods() {
      this.loadingPaymentMethods = true;
      this.paymentMethodsLoadStatus = 1;

      let formData = new FormData()
      formData.append('user_id', this.$store.state.auth.user.id);

      await axios.post('/api/user/payment-methods', formData)
          .then( function( response ){
            this.paymentMethods = response.data;

            this.paymentMethodsLoadStatus = 2;
          }.bind(this));
      this.loadingPaymentMethods = false;
    },
    toggleSelectedMethod(method) {
      if (this.paymentMethodSelected && this.paymentMethodSelected.id == method.id) {
        this.paymentMethodSelected = {};
      } else {
        this.paymentMethodSelected = method;
      }
    },
    async purchase() {
      // Make a call to check if user got PREMIUM or is SUPER ADMIN (skip payment)
      let premium = await axios.get('/api/user')
          .then( function( response ){
            if (response && response.data) {
              if (response.data.role && response.data.role.name === 'Super Admin' ||
                  response.data.role && response.data.role.name === 'Premium') {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          }.bind(this));

      // Trigger card payment only if paid advertise was chosen
      if (this.subscriptionPlan === this.subPaidValue && !premium) {
        this.paymentStatus = 1;

        let savePaymentMethod = true;
        let paymentMethod = {};

        if (this.paymentMethodSelected && Object.keys(this.paymentMethodSelected).length > 0) {
          paymentMethod = this.paymentMethodSelected.id;
          savePaymentMethod = false;
        } else {
          paymentMethod = {
            card: this.card,
            billing_details: {
              name: this.cardHolderName
            }
          };
        }

        return this.stripe.confirmCardSetup(
            this.intentToken.client_secret, {
              payment_method: paymentMethod
            }
        ).then(async function (result) {
          if (result.error) {
            this.paymentStatus = 3;
            this.paymentError = result.error.message;
            // Reset selected payment method
            this.paymentMethodSelected = {};

            return this.paymentError;
            // console.log(result);
          } else {
            this.paymentMethodSelected = result.setupIntent.payment_method;
            // console.log(result);
            // console.log('start NOT paymentMethodSelected');
            // console.log(result.setupIntent.payment_method);
            // console.log(this.paymentMethodSelected);
            // console.log('end NOT paymentMethodSelected');
            if (savePaymentMethod) {
              this.savePaymentMethod(result.setupIntent.payment_method);
            }
            let payment = await this.oneOffChargeMethod(result.setupIntent.payment_method);

            // Reset selected payment method
            this.paymentMethodSelected = {};

            if (payment === true) {
              // Here I will add updateSubscription(method) to update subscription and pass payment_method to it
              // this.updateSubscription();
              this.paymentStatus = 2;
              this.card.clear();
              this.cardHolderName = '';
              // console.log(result);

              // prepare object to return
              return {
                id: this.paymentId,
                status: true,
                paymentAmount: this.paymentAmount
              };
            }
          }
        }.bind(this)).finally(async () => {
          // Reload user intent so we can do another purchase just in case
          await this.loadUserIntentSubscribe();
          // Reset selected payment method
          this.paymentMethodSelected = {};
          this.processing = false;
        });
      // if user got PREMIUM or is SUPER ADMIN (skip payment)
      } else if (premium) {
        // Reset selected payment method
        this.paymentMethodSelected = {};
        // Premium ad
        // prepare object to return
        return {
          id: 'PREMIUM',
          status: true,
          paymentAmount: 0
        };
      } else {
        // Reset selected payment method
        this.paymentMethodSelected = {};
        // Free ad
        // prepare object to return
        return {
          id: 'FREE',
          status: true,
          paymentAmount: 0
        };
      }
    },
    savePaymentMethod(method){
      let formData = new FormData()

      formData.append('user_id', this.$store.state.auth.user.id);
      formData.append('payment_method', method);

      axios.post('/api/user/payments', formData).then(function(){
        this.loadPaymentMethods();
      }.bind(this));
    },
    async removePaymentMethod(paymentID){
      // Prevent user to be able to delete payments methods if their subscription is active
      let formData = new FormData()

      formData.append('user_id', this.$store.state.auth.user.id);
      formData.append('id', paymentID);
      // Prevent user to be able to delete payments methods if their subscription is active
      await axios.post('/api/user/remove-payment', formData).then(function(response){
        this.loadPaymentMethods();
      }.bind(this));
    },
    // updateSubscription(){
    //   axios.put('/api/user/subscription', {
    //     plan: this.subscriptionPlan,
    //     payment: this.paymentMethodSelected
    //   }).then(function(response){
    //     console.log(response);
    //     alert('You Are Subscribed!');
    //   }.bind(this));
    // },
    async oneOffChargeMethod(method) {
      return await axios.post('/api/user/one-off-charge', {
        payment_method: method,
        plan: this.subscriptionPlan
      }).then(function(response) {
        if (response && response.data) {
          this.paymentAmount = response.data.amount;
          this.paymentId = response.data.id;
        } else {
          this.paymentAmount = 0;
          this.paymentId = 'PREMIUM';
        }
        return true;
      }.bind(this)).catch(({response})=> {
        if (response && response.data && response.data.errors) {
          if (response.data.errors instanceof Array) {
            this.validationErrors = response.data.errors;
          } else {
            for (const [key, value] of Object.entries(response.data.errors)) {
              this.validationErrors.push(value);
            }
          }
        }
        return false;
      });
    },
  },
  created() {
    // console.log('created subscribe component');
  },
  watch: {
    "subscriptionPlan": function (subPlan) {
      if (subPlan === this.subPaidValue) {
        this.$emit('isPaidAd', 1);
      } else if (subPlan === this.subFreeValue) {
        this.$emit('isPaidAd', 0);
      } else {
        this.$emit('isPaidAd', '');
      }
    }
  }
}
</script>

<style lang="scss">

.subscribe {
  /* start tooltip - popups info on icons */
  .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    font-size: 10px;
    border-radius: 6px;
    padding: 5px 5px;

    /* Position the tooltip */
    z-index: 1000;
  }

  .plan-box .loader {
    position: relative;
    display: block;
    min-height: 180px;
    top: 0;
    left: 0;
  }

  .payment-method {
    font-size: 13px;
    background-color: #f1f1f1;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    &:hover {
      background-color: white;
      color: black;
      transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
    }
  }

  .bg-selected-payment-method {
    background-color: #bdfdbd;
    color: black !important;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    &:hover {
      background-color: #bdfdbd !important;
      color: black !important;
      transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
    }
  }

  .delete-payment-method-btn {
    display: block;
    width: auto;
    height: auto;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    .delete-icon {
      transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      border-radius: 50%;
      color: red;
      background-color: white;
    }
    .delete-icon:hover {
      transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      border-radius: 50%;
      color: white;
      background-color: red;
    }
  }
}

  .subscription-label {
    position: relative;
    height: 120px;
    width: 100%;
    min-width: 100%;
    border-radius: 10px;
    border: 1px solid #ced4da;
    box-shadow: 0rem 0.5rem 0.5rem rgba(0, 0, 0, .25);
    padding: 5px;
    margin: 5px 0px 15px 0px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  .subscription-label:hover {
    cursor: pointer;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
  }
  .subscription-tickbox-container {
    position: absolute;
    left: 0;
    bottom: 0;
    text-align: center;
    width: 100%;
    color: limegreen;
    font-weight:bold;
  }
</style>