<template>
  <div v-if="editor">
<!--    <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">-->
<!--      bold-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">-->
<!--      italic-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">-->
<!--      strike-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().clearNodes().run()">-->
<!--      clear nodes-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">-->
<!--      paragraph-->
<!--    </button>-->
<!--    &lt;!&ndash; Start Put that in select dropdown &ndash;&gt;-->
<!--    <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">-->
<!--      h1-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">-->
<!--      h2-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">-->
<!--      h3-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">-->
<!--      h4-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">-->
<!--      h5-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">-->
<!--      h6-->
<!--    </button>-->
<!--    &lt;!&ndash; End Put that in select dropdown &ndash;&gt;-->
<!--    <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">-->
<!--      bullet list-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">-->
<!--      ordered list-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">-->
<!--      blockquote-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().setHorizontalRule().run()">-->
<!--      horizontal rule-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().setHardBreak().run()">-->
<!--      hard break-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().toggleHighlight().run()" :class="{ 'is-active': editor.isActive('highlight') }">-->
<!--      highlight-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">-->
<!--      left-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">-->
<!--      center-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">-->
<!--      right-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">-->
<!--      justify-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().undo().run()">-->
<!--      undo-->
<!--    </button>-->
<!--    <button @click="editor.chain().focus().redo().run()">-->
<!--      redo-->
<!--    </button>-->
    <div class="tiptap-icons">
      <template v-for="(item, index) in items">
        <div class="divider" v-if="item.type === 'divider'" :key="`divider${index}`" />
        <MenuItem v-else :key="index" v-bind="item" />
      </template>
    </div>

    <bubble-menu
        class="bubble-menu"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
        v-if="editor"
    >
      <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        Bold
      </button>
      <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        Italic
      </button>
      <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        Strike
      </button>
    </bubble-menu>

    <floating-menu
        class="floating-menu"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
        v-if="editor"
    >
      <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        H3
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
        H4
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
        H5
      </button>
      <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        Bullet List
      </button>
      <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        Ordered List
      </button>
    </floating-menu>

    <editor-content :editor="editor" id="content"/>

    <div v-if="editor" :class="{'character-count': true, 'character-count--warning': editor.getCharacterCount() === limit}">
      <!-- Start Character count -->
      <ul class="ul-inline">
        <li>
          <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              class="character-count__graph"
          >
            <circle
                r="10"
                cx="10"
                cy="10"
                fill="#e9ecef"
            />
            <circle
                r="5"
                cx="10"
                cy="10"
                fill="transparent"
                stroke="currentColor"
                stroke-width="10"
                :stroke-dasharray="`calc(${percentage} * 31.4 / 100) 31.4`"
                transform="rotate(-90) translate(-20)"
            />
            <circle
                r="6"
                cx="10"
                cy="10"
                fill="white"
            />
          </svg>
        </li>
        <li>
          <div class="character-count__text">
            {{ editor.getCharacterCount() }}/{{ limit }} characters
          </div>
        </li>
      </ul>
      <!-- End Character count -->
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu, FloatingMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import CharacterCount from '@tiptap/extension-character-count'
import MenuItem from './MenuItem.vue'

export default {
  components: {
    'EditorContent': EditorContent,
    'BubbleMenu': BubbleMenu,
    'FloatingMenu': FloatingMenu,
    'MenuItem': MenuItem
  },
  props: ['modelValue'],
      // {
      //   modelValue: {
      //     type: String,
      //     default: '',
      //   },
      // },
  data() {
    return {
      descValue: this.modelValue,
      editor: null,
      editorChange: false,
      limit: 3000,
      items: [
        {
          icon: 'bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          icon: 'strikethrough',
          title: 'Strike',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike'),
        },
        // {
        //   icon: 'highlighter',
        //   title: 'Highlight',
        //   action: () => this.editor.chain().focus().toggleHighlight().run(),
        //   isActive: () => this.editor.isActive('highlight'),
        // },
        {
          icon: 'align-left',
          title: 'Text Align Left',
          action: () => this.editor.chain().focus().setTextAlign('left').run(),
          isActive: () => this.editor.isActive({ textAlign: 'left' }),
        },
        {
          icon: 'align-center',
          title: 'Text Align Center',
          action: () => this.editor.chain().focus().setTextAlign('center').run(),
          isActive: () => this.editor.isActive({ textAlign: 'center' }),
        },
        {
          icon: 'align-right',
          title: 'Text Align Right',
          action: () => this.editor.chain().focus().setTextAlign('right').run(),
          isActive: () => this.editor.isActive({ textAlign: 'right' }),
        },
        {
          icon: 'align-justify',
          title: 'Text Justify',
          action: () => this.editor.chain().focus().setTextAlign('justify').run(),
          isActive: () => this.editor.isActive({ textAlign: 'justify' }),
        },
        // {
        //   type: 'divider',
        // },
        {
          icon: 'heading',
          title: 'Heading 3',
          action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 3 }),
        },
        {
          icon: 'heading',
          title: 'Heading 4',
          action: () => this.editor.chain().focus().toggleHeading({ level: 4 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 4 }),
        },
        {
          icon: 'heading',
          title: 'Heading 5',
          action: () => this.editor.chain().focus().toggleHeading({ level: 5 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 5 }),
        },
        {
          icon: 'paragraph',
          title: 'Paragraph',
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive('paragraph'),
        },
        {
          type: 'divider',
        },
        {
          icon: 'list-ul',
          title: 'Bullet List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'list-ol',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
        },
        {
          icon: 'quote-right',
          title: 'Blockquote',
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
          isActive: () => this.editor.isActive('blockquote'),
        },
        {
          icon: 'ruler-horizontal',
          title: 'Horizontal Rule',
          action: () => this.editor.chain().focus().setHorizontalRule().run(),
        },
        // {
        //   type: 'divider',
        // },
        // {
        //   icon: 'text-wrap', // no icon set here
        //   title: 'Hard Break',
        //   action: () => this.editor.chain().focus().setHardBreak().run(),
        // },
        {
          icon: 'hand-sparkles',
          title: 'Clear Format',
          action: () => this.editor.chain()
              .focus()
              .clearNodes()
              .unsetAllMarks()
              .run(),
        },
        // {
        //   type: 'divider',
        // },
        {
          icon: 'undo',
          title: 'Undo',
          action: () => this.editor.chain().focus().undo().run(),
        },
        {
          icon: 'redo',
          title: 'Redo',
          action: () => this.editor.chain().focus().redo().run(),
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.editor = new Editor({
        extensions: [
          StarterKit,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          CharacterCount.configure({
            limit: this.limit,
          }),
        ],
        content: this.modelValue, // this.modelValue
        onUpdate: () => {
          this.editorChange = true;
          // HTML
          this.$emit('input', this.editor.getHTML())

          // JSON
          // this.$emit('update:modelValue', this.editor.getJSON())
        },
        editorProps: {
          attributes: {
            class: 'content-styling'
          },
        },
      })
    });

  },
  computed: {
    percentage() {
      return Math.round((100 / this.limit) * this.editor.getCharacterCount())
    },
  },
  beforeUnmount() {
    if(this.editor) {
      this.editor.destroy()
    }
  },
  watch: {
    modelValue(value, oldValue) {
      if (this.editor && !this.editorChange) {
        this.editor.commands.setContent(value, true) // false
        // // HTML
        // const isSame = this.editor.getHTML() === value
        // // JSON
        // // const isSame = this.editor.getJSON().toString() === value.toString()
        // if (isSame) {
        //   return
        // }
      }
      this.editorChange = false;
      // this.editor.commands.setContent(value, true) // false
    },
  },
}
</script>

<style scoped lang="scss">
/* Basic editor styles */
#content {
  text-align: left;
}

ul.ul-inline {
  display: block;
  list-style-type: none;
  li {
    display: inline-block;
  }
}

.tiptap-icons {
  width: 100%;
  border: 1px solid #9e9e9e;
  border-radius: 10px;
  padding: 5px;
  margin: 2px 0 2px 0;
}

.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
  ul,
  ol {
    padding: 0 1rem;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }
}

.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;
    transition: 0.6s;
    border-radius: 5px;

    &:hover,
    &.is-active {
      background-color: #9e9e9e;
      color: black;
      opacity: 1;
      transition: 0.6s;
    }
  }
}

.floating-menu {
  display: flex;
  background-color: #0D0D0D10;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: 1px solid #9e9e9e;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;
    border-radius: 5px;
    transition: 0.6s;

    &:hover,
    &.is-active {
      background-color: #9e9e9e;
      opacity: 1;
      transition: 0.6s;
    }
  }
}

.character-count {
  margin: auto;
  display: block;
  width: 100%;
  color: #68CEF8;

  &--warning {
    color: #FB5151;
  }

  &__graph {
    margin-right: 0.5rem;
  }

  &__text {
    color: #868e96;
  }
}

</style>