<template>
  <span>
    <button
        class="menu-item"
        :class="{ 'is-active': isActive ? isActive(): null }"
        @click="action"
        :title="title"
    >
      <font-awesome-icon :icon="this.icon" class="icon-fa" />
    </button>
    <sub v-if="this.title === 'Heading 3'" class="heading-subscript">3</sub>
    <sub v-if="this.title === 'Heading 4'" class="heading-subscript">4</sub>
    <sub v-if="this.title === 'Heading 5'" class="heading-subscript">5</sub>
  </span>
</template>

<script>
// import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    action: {
      type: Function,
      required: true,
    },

    isActive: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      // remixiconUrl,
    }
  },
}
</script>

<style lang="scss">
.menu-item {
  width: 1.75rem;
  height: 1.75rem;
  color: #0D0D0D;
  border: none;
  background-color: transparent;
  border-radius: 0.4rem;
  padding: 0.25rem;
  margin-right: 0.25rem;

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  &.is-active,
  &:hover {
    color: #FFF;
    background-color: #0D0D0D;
  }
}

.heading-subscript {
  margin-left: -10px;
  padding: 1px;
  z-index: 2;
  color: white;
  background-color: black;
  border-radius: 10px;
}
</style>